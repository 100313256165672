import CommonAnalytics, { EVENTS } from "../analytics"

import { DEFAULT_PAGE_SIZE } from "./"

const nextOffset = offset => offset + DEFAULT_PAGE_SIZE

export const loadMore = (filter, pageInfo, entityType, callback) => {
  const { offset, count } = pageInfo

  CommonAnalytics.trackEvent(
    EVENTS.LOAD_MORE_CLICKED,
    CommonAnalytics.paginationProperties(offset),
    CommonAnalytics.pageProperties(entityType)
  )

  callback(filter, { offset: nextOffset(offset), count })
}
