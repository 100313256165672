import { BRAND_FILTER_TYPES, DEFAULT_PAGE_SIZE } from "../../../common/utils"
import { filter$, search$ } from "../../../search/store/selectors"

import SearchFields from "../../../search/utils/search-fields"
import { createSelector } from "reselect"
import get from "lodash/get"
import pick from "lodash/pick"
import { searchableTextFromProperties } from "../../../search/utils"

const entitiesState = state => state.entities
const brandsState = state => state.brands
const pageInfoState = state => state.pageInfo

const defaultPageInfo = {
  offset: 0,
  count: 0,
}

const pageInfo$ = createSelector([pageInfoState], state => state.brands)

export const selectedBrandId$ = (_, props) => props.brandId

export const pageContextId$ = (_, props) => props.pageContext.id

export const walletItems$ = createSelector(
  [brandsState],
  state => state.walletBrands
)

export const walletItemsEntities$ = createSelector(
  [entitiesState],
  state => state.walletItems
)

export const walletItemsArray$ = createSelector([walletItemsEntities$], state =>
  Object.values(state)
)

/**
 * handle gatsby source data
 */

export const internalBrand$ = (_, props) =>
  get(props, "data.internalBrands", {})

export const allInternalBrandOffers$ = (_, props) =>
  get(props, "data.allInternalOffers", {})

export const internalBrandEntity$ = createSelector(
  [internalBrand$],
  state => state
)

export const internalBrandOfferEntities$ = createSelector(
  [allInternalBrandOffers$],
  state => get(state, "edges", []).map(edge => edge.node)
)

/**
 * end - handle gatsby source data
 */

export const brandEntities$ = createSelector(
  [entitiesState],
  state => state.brands
)

// filter the brand entities by the selected brand type
export const filteredBrandEntities$ = createSelector(
  [brandEntities$, walletItemsEntities$, brandsState, filter$],
  (brands, walletItems, brandsState, filter) => {
    const entities = { ...brands, ...walletItems } // we do this so the wallet brands have a walletItemId
    if (filter === BRAND_FILTER_TYPES.WALLET) {
      return pick(entities, brandsState[BRAND_FILTER_TYPES.WALLET])
    } else {
      return entities
    }
  }
)

// filter the pageInfo by the selected brand type
export const filteredPageInfo$ = createSelector(
  [filter$, pageInfo$],
  (filter, pageInfo) => ({ ...defaultPageInfo, ...pageInfo[filter] })
)

// return an array of the filtered brand entities
export const filteredBrandsArray$ = createSelector(
  [filteredBrandEntities$],
  brands => Object.values(brands) // sort here
)

// paginate the filtered brands array
export const paginatedFilteredBrandsArray$ = createSelector(
  [filteredBrandsArray$, filteredPageInfo$],
  (brands, pageInfo) => brands.slice(0, pageInfo.offset + DEFAULT_PAGE_SIZE)
)

// filter the brands for an brand type by the search term
export const searchResults$ = createSelector(
  [filteredBrandsArray$, search$],
  (brands, search) => {
    const results = brands.filter(brand => {
      if (!search) return true
      return searchableTextFromProperties(SearchFields.BRANDS, brand)
        .toLowerCase()
        .includes(search.toLowerCase())
    })
    return results
  }
)

// paginate the search results
export const paginatedSearchResults$ = createSelector(
  [searchResults$, filteredPageInfo$],
  (brands, pageInfo) => {
    return brands.slice(0, pageInfo.offset + DEFAULT_PAGE_SIZE)
  }
)

export const searchResultsCount$ = createSelector(
  [searchResults$, filteredPageInfo$, search$],
  (brands, pageInfo, search) => (search === "" ? pageInfo.count : brands.length)
)

export const canLoadMore$ = createSelector(
  [filteredPageInfo$, searchResultsCount$],
  (pageInfo, searchResultCount) =>
    pageInfo.offset + DEFAULT_PAGE_SIZE < searchResultCount
)

export const walletItemId$ = createSelector(
  [pageContextId$, brandEntities$],
  (brandId, brands) => {
    const brand = brands[brandId]
    return get(brand, "walletItemId")
  }
)
