import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"

function Loading({ text }) {
  return (
    <div className="flex flex-col mx-2 justify-start items-center py-12">
      <div className="flex flex-col justify-center items-center text-gray-700 p-6">
        <FontAwesomeIcon icon={faCircleNotch} size="5x" spin={true} />
      </div>
      <div className="flex flex-col justify-center items-center text-gray-700">
        <div className="flex-row">
          <p className="text-center">{text}</p>
        </div>
      </div>
    </div>
  )
}

Loading.propTypes = {
  text: PropTypes.string,
}

Loading.defaultProps = {
  text: "",
}

export default Loading
