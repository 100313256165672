import * as selectors from "../../auth/store/selectors/token-selector"

import React, { useEffect, useState, useContext } from "react"
import Filter from "./Filter"
import PropTypes from "prop-types"
import actionCreators from "../store/actions/action-creators"
import { connect } from "react-redux"
import { BuildContext } from "../../common/utils/index"

export const useSearchForm = callback => {
  const [inputs, setInputs] = useState({})

  const handleInputChange = event => {
    if (event) {
      event.preventDefault()
    }
    callback(event.target.value)

    event.persist()
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))
  }
  return {
    handleInputChange,
    inputs,
  }
}

function Search({
  placeholder,
  onToggleFilter,
  addSearch,
  resultCount,
  isAuthenticated,
  entityType,
  addSearchEntity,
  filterComponent,
  onFilterChange,
  filter,
  disableFilter,
}) {
  useEffect(() => {
    addSearchEntity(entityType)
    return () => {
      addSearch("")
    }
  }, [])

  const { inputs, handleInputChange } = useSearchForm(addSearch)
  const { isProduction } = useContext(BuildContext)
  return (
    <div className="flex flex-row justify-center md:justify-end w-full">
      {isProduction || !filter || disableFilter ? null : (
        <div className="flex-col w-full md:w-1/2 lg:w-1/3 xl:w-1/4 hover:underline focus:outline-none rounded-none px-2">
          <Filter
            filter={filter}
            onFilterChange={onFilterChange}
            entityType={entityType}
          />
        </div>
      )}
      <div className="flex-col w-full md:w-1/2 lg:w-1/3 xl:w-1/4 hover:underline focus:outline-none rounded-none px-2">
        <input
          type="search"
          onChange={handleInputChange}
          value={inputs.value}
          name="search"
          placeholder={placeholder}
          style={{ minHeight: 38 }}
          className="w-full text-base font-medium border p-1 rounded-none outline-none px-2 placeholder-gray-500"
        />
      </div>
    </div>
  )
}

Search.propTypes = {
  placeholder: PropTypes.string,
  addSearch: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  addSearchEntity: PropTypes.func.isRequired,
  disableFilter: PropTypes.bool,
}

const mapStateToProps = state => ({
  isAuthenticated: selectors.isSignedIn$(state),
})

const mapDispatchToProps = {
  addSearch: actionCreators.addSearch,
  addSearchEntity: actionCreators.addSearchEntity,
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
