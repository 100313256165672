import React, { useState, useEffect } from "react"

const ImageWithPlaceholder = ({ src, name }) => {
  const [loaded, setLoaded] = useState(false)
  const mountRef = React.useRef(false)

  useEffect(() => {
    mountRef.current = true
    return () => (mountRef.current = false)
  }, [])

  const handleImageLoaded = () => {
    if (mountRef.current) {
      setLoaded(true)
    }
  }

  return (
    <>
      {!loaded ? (
        <svg width="100%" height="100%" viewBox="0 0 300 250">
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            className="text-2xl text-center"
          >
            {name}
          </text>
          <rect x="0" y="0" width="300" height="250" fill="transparent" />
        </svg>
      ) : null}
      <img
        src={src}
        alt={name}
        className={`w-full h-full bg-cover bg-center bg-gray-300 ${
          !loaded ? "hidden" : ""
        }`}
        ref={input => {
          // onLoad replacement for SSR
          if (!input) {
            return
          }
          const img = input

          img.onload = handleImageLoaded
          if (img.complete) {
            handleImageLoaded()
          }
        }}
      />
    </>
  )
}

export default ImageWithPlaceholder
