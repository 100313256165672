import PropTypes from "prop-types"
import React from "react"

function LoadMore({ onClick, text }) {
  return (
    <button
      onClick={onClick}
      className="border p-4 text-2xl hover:bg-gray-200 font-light text-gray-600 text-center focus:outline-none"
    >
      {text}
    </button>
  )
}

LoadMore.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
}

LoadMore.defaultProps = {
  text: "Load More",
  onClick: () => {},
}

export default LoadMore
