import * as brandSelectors from "../store/selectors"

import {
  BRAND_FILTER_TYPES,
  ENTITY_TYPES,
  entityFilterlabel,
} from "../../common/utils"
import React, { useState } from "react"
import {
  useDisabledIntercomEffect,
  useSearchResultEffect,
} from "../../common/effects"

import BrandList from "../components/BrandList"
import EntitiesContainer from "../../common/components/EntitiesContainer"
import Layout from "../../layout/components/Layout"
import Loading from "../../common/components/Loading"
import NoResults from "../../common/components/NoResults"
import SEO from "../../layout/components/SEO"
import SearchableItems from "../../search/components/SearchableItems"
import { addBrandPageInfo } from "../store/actions/actionCreators"
import { connect } from "react-redux"
import { isLoadBrandsLoading$ } from "../store/selectors/loadingSelectors"
import { loadMore } from "../../common/utils/pagination"

function BrandsTemplate({
  location,
  isAuthenticated,
  pageInfo,
  loading,
  canLoadMore,
  searchResults,
  searchResultsCount,
  addFilter,
  loadWalletItemsStarted,
  addBrandPageInfo,
}) {
  const [results, setResults] = useState(searchResults)

  useDisabledIntercomEffect()
  useSearchResultEffect(setResults, searchResults)

  const handleLoadMore = () =>
    loadMore(
      BRAND_FILTER_TYPES.ALL,
      pageInfo,
      ENTITY_TYPES.BRANDS,
      addBrandPageInfo
    )

  return (
    <Layout>
      <SEO
        title="Brands"
        keywords="loyalty offers rewards brands whatsmine"
        url={location.href}
      />

      <SearchableItems
        title={entityFilterlabel(ENTITY_TYPES.BRANDS, BRAND_FILTER_TYPES.ALL)}
        resultCount={searchResultsCount}
        canLoadMore={canLoadMore}
        loadMore={handleLoadMore}
        entityType={ENTITY_TYPES.BRANDS}
        disableFilter={true}
      >
        <EntitiesContainer>
          {results.length ? (
            <BrandList entities={results} />
          ) : loading ? (
            <Loading text="Loading brands..." />
          ) : (
            <NoResults />
          )}
        </EntitiesContainer>
      </SearchableItems>
    </Layout>
  )
}

const mapStateToProps = (state, props) => ({
  pageInfo: brandSelectors.filteredPageInfo$(state),
  loading: isLoadBrandsLoading$(state),
  canLoadMore: brandSelectors.canLoadMore$(state),
  searchResults: brandSelectors.paginatedSearchResults$(state),
  searchResultsCount: brandSelectors.searchResultsCount$(state),
})

const mapDispatchToProps = {
  addBrandPageInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsTemplate)
