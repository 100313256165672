import React, { useState, useEffect } from "react"
import Select from "react-select"
import { entityFilterOptions } from "../../common/utils"
import { PropTypes } from "prop-types"

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    borderColor: "#FFF",
    backgroundColor: "#FFF",
    boxShadow: "none",
    borderWidth: 0,
    borderRadius: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  }),
}

const Filter = ({ onFilterChange, filter, entityType }) => {
  const filterOptions = entityFilterOptions(entityType)
  const [filterOption, setFilterOption] = useState(
    filterOptions.find(fo => fo.value === filter)
  )

  useEffect(() => {
    if (!filterOption && !!filter) {
      setFilterOption(filterOptions.find(fo => fo.value === filter))
    }
  }, [filter, filterOption, filterOptions])

  const handleFilterChange = selected => {
    setFilterOption(selected)
    onFilterChange(selected.value)
  }

  return filter && filterOption ? (
    <Select
      id="inline-filter"
      options={filterOptions}
      value={filterOption}
      onChange={handleFilterChange}
      styles={customStyles}
      className="w-full text-base font-medium rounded-none text-gray-600"
    />
  ) : null
}

Filter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
}

export default Filter
