import AnimatedCard from "../../common/components/AnimatedCard"
import ImageWithPlaceholder from "../../brands/components/ImageWithPlaceholder"
import { Link } from "gatsby"
import React from "react"
import ResponsiveCard from "../../common/components/ResponsiveCard"
import path from "path"
import { slugifyBrand } from "../../common/utils"

const Card = ({ item }) => {
  const { uuid, card_asset_image, name } = item
  const brandPath = path.join(
    "/",
    item.is_stamp ? "cafes" : "brands",
    slugifyBrand(item)
  )

  return (
    <ResponsiveCard
      key={uuid}
      className="flex w-1/2 md:w-1/3 xl:w-1/4 justify-center"
    >
      <AnimatedCard className="flex max-w-sm shadow-lg bg-gray-300 m-2 w-full border border-gray-400 relative">
        {/* {item.walletItemId && (
          <small className="z-50 p-1 border border-orange-400 bg-orange-400 text-white text-xs font-medium absolute right-0 -mt-4 -mr-2">
            WALLET
          </small>
        )} */}
        <Link to={brandPath} className="flex flex-col w-full">
          <ImageWithPlaceholder
            src={card_asset_image ? card_asset_image.medium : null}
            name={name}
          />
        </Link>
      </AnimatedCard>
    </ResponsiveCard>
  )
}

export default Card
