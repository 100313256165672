import PropTypes from "prop-types"
import React from "react"
import sadTruffle from "../../images/WhatsMine_Truffle_Sad.png"

function NoResults({ title, text }) {
  return (
    <div className="flex flex-col mx-2 justify-start items-center py-12">
      <div className="flex flex-col justify-center items-center text-gray-700 p-6">
        <img src={sadTruffle} style={{ width: 200 }} alt="Sad Truffle" />
      </div>
      <div className="flex flex-col justify-center items-center text-gray-700">
        {title && (
          <div className="flex-row">
            <h1 className="text-2xl mt-0">{title}</h1>
          </div>
        )}
        <div className="flex-row">
          <p className="text-center">{text}</p>
        </div>
      </div>
    </div>
  )
}

NoResults.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

NoResults.defaultProps = {
  text: "Sorry, I couldn't find anything.",
}

export default NoResults
