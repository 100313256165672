import React, { useState } from "react"

import HeroContainer from "../../common/components/HeroContainer"
import LoadMore from "../../common/components/LoadMore"
import PropTypes from "prop-types"
import Search from "../../search/components/Search"

const SearchableItems = ({
  searchableItems, // All of the searchable items
  setResult, // Callback for the array result of filtering searchableItems
  searchFields, // see search-fields.js
  canLoadMore,
  loadMore,
  children,
  filterComponent,
  resultCount,
  entityType,
  title,
  onFilterChange,
  filter,
  disableFilter,
}) => {
  const [filterVisible, setFilterVisible] = useState(false)
  const toggleFilter = () => setFilterVisible(!filterVisible)

  return (
    <div className="mb-12">
      <HeroContainer>
        <Search
          placeholder={`Search ${title}...`}
          onToggleFilter={toggleFilter}
          resultCount={resultCount}
          entityType={entityType}
          filterComponent={filterComponent}
          onFilterChange={onFilterChange}
          filter={filter}
          disableFilter={disableFilter}
        />
      </HeroContainer>

      <div className="container mx-auto flex flex-row text-sm font-medium w-full justify-end py-1 px-2">
        Found {resultCount} results
      </div>

      {children}

      {canLoadMore && (
        <div className="w-full flex flex-row justify-center">
          <LoadMore onClick={loadMore} />
        </div>
      )}
    </div>
  )
}

SearchableItems.propTypes = {
  setResult: PropTypes.func,
  loadMore: PropTypes.func,
  canLoadMore: PropTypes.bool,
  title: PropTypes.string,
  entityType: PropTypes.string.isRequired,
}

SearchableItems.defaultProps = {
  canLoadMore: false,
  loadMore: () => {},
  title: "",
  disableFilter: false,
}

export default SearchableItems
