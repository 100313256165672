import { createSelector } from "reselect"
import get from "lodash/get"

const loadingState = state => state.loading

const loadingActions = {
  LOAD_BRANDS: "LOAD_BRANDS",
}

export const isLoadBrandsLoading$ = createSelector([loadingState], state =>
  get(state, loadingActions.LOAD_BRANDS)
)
